<template>
  <div class="product-detail-view">
    <Breadcrumb :items="breadcrumb" />

    <v-container fluid v-if="product.productId">
      <ProductDetail
        v-if="product.productId"
        :product="product"
        :selectedImage="selectedImage"
        :key="'product_detail_' + key"
        @selectImage="selectImage"
      />
      <ProductDescription
        v-if="product.productId"
        :product="product"
        :key="'product_description_' + product.productId + '_key_' + key"
        :productMetadata="product.metaData"
        :ingredientList="product.ingredientList"
      />
    </v-container>
    <not-found v-else />
    <div v-if="showRelated" class="releated" :key="product.productId">
      <v-tabs
        :slider-size="0"
        fixed-tabs
        show-arrows
        v-model="selectedTab"
        class="d-flex justify-space-between"
      >
        <v-tab v-if="product.variations && product.variations.length > 0">
          <h4>Altri prodotti della stessa linea</h4>
        </v-tab>
        <v-tab
          v-for="config in category.categoryBlocks"
          :key="config.categoryBlockId"
          :class="`category-block-${config.categoryBlockId}`"
        >
          <h4>
            {{
              $ebsn.meta(
                config,
                `categoryblocktype_${config.templateBlockType.modeName}.TITLE`
              )
            }}
          </h4>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab" class="pa-7" :show-arrows="false">
        <v-tab-item v-if="product.variations && product.variations.length > 0">
          <ProductListSlider
            :productList="product.variations"
            :key="product.productId + '_3'"
            :paginationClass="'swiper-pagination-product_variations'"
            position="product_variations"
            :showArrows="true"
          >
          </ProductListSlider>
        </v-tab-item>
        <v-tab-item
          v-for="config in category.categoryBlocks"
          :key="config.categoryBlockId"
          :class="`category-block category-block-${config.categoryBlockId}`"
        >
          <!-- default layout 220 -> Altri clienti hanno acquistato: -->
          <category-block
            :target="category"
            :productId="product.productId"
            :config="config"
            position="product1"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<style lang="scss">
.product-detail-view {
  .back-button {
    &:hover {
      opacity: 0.5;
    }
  }
}
.releated {
  h4 {
    font-weight: normal;
    color: $text-color;
    text-transform: uppercase;
    font-size: 1rem;
  }
  &.container {
    padding: 0;
    margin: 30px 0;
  }
  .v-slide-group__wrapper {
    position: relative;
    top: 2px;
    .v-slide-group__content {
      width: fit-content;
    }
  }
  .v-tabs-bar {
    height: 52px;
    width: 100%;
  }
  .v-tabs-items {
    background: var(--v-grey-lighten2);
    padding: 12px;
    border-top: none;
  }
  .v-tab {
    background-color: var(--v-grey-lighten1);
    letter-spacing: 0px;
    border-radius: $border-radius-root $border-radius-root 0 0;
    margin-right: 8px;
    text-transform: none;
    font-size: 1rem;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0px 5px;
    }
  }
  .v-tab--active {
    font-weight: bold;
    background-color: var(--v-grey-lighten2);
  }
  table {
    width: 100%;
    // tr:nth-child(odd) {
    //   background-color: var(--v-grey-lighten1);
    // }
    border-collapse: collapse;

    td {
      border-bottom: 1px solid var(--v-grey-base);
      padding: 3px 0px;
    }
  }
  .v-window {
    background-color: var(--v-grey-lighten2);
  }
}
</style>
<script>
import join from "lodash/join";
import compact from "lodash/compact";
import get from "lodash/get";

import ProductDescription from "@/components/product/ProductDescription.vue";
import ProductDetail from "@/components/product/ProductDetail.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import NotFound from "@/components/category/not-found.vue";

import categoryMixin from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";

export default {
  name: "Product",
  mixins: [categoryMixin, deliveryReactive],
  components: {
    ProductDescription,
    ProductDetail,
    Breadcrumb,
    ProductListSlider,
    NotFound
    // ,
    // CategoryBlock
  },
  data() {
    return {
      product: {
        productId: null,
        name: "",
        shortDescr: "",
        description: ""
      },
      showNoProductCard: false,
      selectedImage: null,
      key: 1,
      layout20length: 0,
      selectedTab: 0,
      showRelated: true
    };
  },

  computed: {
    getFullName() {
      return join(
        compact([
          this.product.name,
          this.product.shortDescr,
          this.product.description
        ]),
        " "
      );
    }
  },
  methods: {
    setProduct(product) {
      if (product) {
        this.product = product;
        if (this.product.categoryId) {
          this.setCategory(this.product.categoryId);
        }
        if (this.product && this.product.media && this.product.media.length) {
          this.selectImage(this.product.media[0]);
        }
        analyticsService.viewProductDetail(this.product);
        analyticsService.clickProduct(this.product);
      } else {
        this.showNoProductCard = true;
      }
    },
    loaded(productsFound) {
      this.layout20length = productsFound;
    },
    async setCategory(categoryId) {
      this.$store.dispatch("category/setCategory", {
        slug: categoryId,
        reset: false
      });
    },
    async selectImage(image) {
      this.selectedImage = image;
      this.selectedImage.thumb = image.medium;
    },
    async reload() {
      //this.$store.dispatch("category/resetFilters");
      //let slug = split(this.category.slug, "@")[0];
      //await this.setCategory(slug);
      await this.setProduct(await ProductService.getProductBySlug(this.slug));
      this.key += 1;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  async created() {
    this.setProduct(this.$route.params.product);
  },
  watch: {
    async $route(to) {
      if (to.params.slug != this.slug) {
        this.slug = to.params.slug;
        this.setProduct(this.$route.params.product);
      }
    }
  },
  jsonld() {
    if (this.product.productId) {
      let images = this.product.media.map(item => item.medium);
      return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: this.product.name,
        image: images,
        description: this.product.description,
        sku: this.product.codInt,
        mpn: this.product.codInt,
        brand: {
          "@type": "Brand",
          name: this.product.shortDescr
        },
        // aggregateRating: {
        //   reviewCount: this.product.ratingSummary.total,
        //   ratingValue: this.product.ratingSummary.avg
        //   // bestRating: // ex: 5,0
        // },
        offers: {
          "@type": "Offer",
          url: process.env.VUE_APP_EBSN_URL + "/" + this.product.slug,
          priceCurrency: "EUR",
          price: this.product.price ? this.product.price : 0,
          priceValidUntil: "2050-12-31", // NON HO UNA DATA VALIDA DI PREZZO
          itemCondition: "https://schema.org/NewCondition",
          availability:
            this.product.available >= 0
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock"
        }
      };
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.gtm,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            this.getFullName +
              " online. " +
              this.category?.name +
              " in offerta e promozione su Basko con consegna in azienda e ritiro in negozio."
          )
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: get(this.product, "metaData.product_seo.SEO_KEYWORDS")
        },
        // Open Graph Metas
        {
          property: "og:locale",
          content: "it_IT"
        },
        {
          property: "og:title",
          content: get(
            this.product,
            "metaData.product_seo.SEO_TITLE",
            this.getFullName
          )
        },
        {
          property: "og:type",
          content: "product"
        },
        {
          property: "og:url",
          content: location.href
        },
        {
          property: "og:image",
          content: get(
            this.product,
            "media[0].large",
            window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          )
        },
        {
          property: "og:image:alt",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            this.getFullName +
              " online. " +
              this.category?.name +
              " in offerta e promozione su Basko con consegna in azienda e ritiro in negozio."
          )
        },
        {
          property: "og:site_name",
          content: "Basko"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: get(
            this.product,
            "metaData.product_seo.SEO_DESCRIPTION",
            this.getFullName +
              " online. " +
              this.category?.name +
              " in offerta e promozione su Basko con consegna in azienda e ritiro in negozio."
          )
        },
        {
          name: "twitter:image",
          content: get(
            this.product,
            "media[0].large",
            window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          )
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
