<template>
  <div
    class="product-description pb-4"
    v-if="tabs.length > 0 && $vuetify.breakpoint.smAndUp"
  >
    <v-tabs
      :slider-size="0"
      fixed-tabs
      show-arrows
      v-model="selectedTab"
      class="d-flex justify-space-between"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
        :class="tab.cssClass"
        class="default--text"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab" class="pa-7" :show-arrows="false">
      <v-tab-item v-for="tab in tabs" :key="tab.title" :class="tab.cssClass">
        <div
          v-for="item in tab.items"
          :key="item.title"
          class="pb-4"
          :class="item.cssClass"
        >
          <h4 v-if="item.title">{{ item.title }}</h4>
          <div
            v-for="path in item.paths"
            :key="path.key"
            v-html="path.content"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <div class="product-description pb-4" v-else-if="tabs.length > 0">
    <v-expansion-panels flat accordion :focusable="selectedTab">
      <v-expansion-panel
        v-for="tab in tabs"
        :key="tab.title"
        :class="tab.cssClass"
      >
        <v-expansion-panel-header class="pa-3 px-sm-3">
          <template v-slot:actions>
            <v-icon color="primary">$expand</v-icon>
          </template>
          <div class="font-weight-bold text-body-1" v-html="tab.title" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-2">
          <div
            v-for="item in tab.items"
            :key="item.title"
            class="pb-4"
            :class="item.cssClass"
          >
            <h4
              v-if="item.title"
              class="mb-1 description-header text-uppercase font-weight-bold"
            >
              {{ item.title }}
            </h4>
            <div
              v-for="path in item.paths"
              :key="path.key"
              class="description-content"
            >
              <component
                :is="path.template || 'DefaultTpl'"
                :content="path.content"
                :append="path.append"
              />
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import DefaultTpl from "./templates/DefaultTpl.vue";
import tabsConfig from "/public/product/descriptionTabsConfig.json";

import { mapGetters } from "vuex";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";

export default {
  name: "ProductDescription",
  /* eslint-disable vue/no-unused-components */
  components: {
    DefaultTpl
  },
  data() {
    return {
      panelOpen: 0,
      selectedTab: null,
      productDescriptionTabs: tabsConfig
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    productMetadata: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    tabs() {
      let tabs = [];
      this.productDescriptionTabs.forEach(tabMeta => {
        let items = [];
        tabMeta.items.forEach(parMeta => {
          let paths = [];
          parMeta.paths.forEach(pathMeta => {
            let content = get(this.product, pathMeta.data || pathMeta);
            let append = pathMeta.append;
            // check if content is empty
            if (content && content !== "<p></p>") {
              // check if table has empty cells
              if (pathMeta.template === "TableTpl") {
                let cells = JSON.parse(content).cells;
                console.log(cells);
                let hasContent = cells.find(c => {
                  return c !== null;
                });
                if (!hasContent) {
                  return;
                }
              }
              if (pathMeta.template === "ValueTpl") {
                let value = JSON.parse(content).value;
                if (!value) {
                  return;
                }
              }
              paths.push({
                key: pathMeta.data || pathMeta,
                content: content,
                template: pathMeta.template,
                append: append
              });
            }
          });
          if (paths.length > 0) {
            items.push({
              title: parMeta.title,
              paths: paths,
              cssClass: get(parMeta, "cssClass", kebabCase(parMeta.title))
            });
          }
        });
        if (items.length > 0) {
          tabs.push({
            title: tabMeta.title,
            items: items,
            cssClass: get(tabMeta, "cssClass", kebabCase(tabMeta.title))
          });
        }
      });

      return tabs;
    }
  }
};
</script>

<style lang="scss">
.product-description {
  h4 {
    font-weight: normal;
    color: $text-color;
    text-transform: uppercase;
    font-size: 1rem;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      line-height: 1.1rem;
    }
  }
  &.container {
    padding: 0;
    margin: 30px 0;
  }
  .v-slide-group__wrapper {
    position: relative;
    top: 2px;
    .v-slide-group__content {
      width: fit-content;
    }
  }
  .v-tabs-bar {
    height: 52px;
    width: 100%;
  }
  .v-tabs-items {
    background: var(--v-green-lighten2);
    padding: 12px;
    border-top: none;
  }
  .v-tab {
    background-color: var(--v-green-lighten1);
    letter-spacing: 0px;
    border-radius: $border-radius-root $border-radius-root 0 0;
    margin-right: 8px;
    text-transform: none;
    font-size: 1rem;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0px 16px;
    }
  }
  .v-tab--active {
    font-weight: bold;
    background-color: var(--v-green-lighten2);
  }
  table {
    width: 100%;
    // tr:nth-child(odd) {
    //   background-color: var(--v-grey-lighten1);
    // }
    border-collapse: collapse;

    td {
      border-bottom: 1px solid var(--v-grey-base);
      padding: 3px 0px;
    }
  }
  .v-window {
    background-color: var(--v-green-lighten2);
  }
  .v-expansion-panel-header {
    border-bottom: 1px solid var(--v-grey-base);
    &.v-expansion-panel-header--active {
      border-bottom: none;
    }
  }
  .v-expansion-panel-content__wrap {
    border-bottom: 1px solid var(--v-grey-base);
  }
}
</style>
