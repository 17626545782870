<template>
  <v-row v-if="product" class="mb-5 mb-sm-7 product-detail">
    <v-col
      cols="12"
      sm="6"
      class="py-0 py-sm-3 default--text"
      style="position:relative"
    >
      <h1 class="mb-1 product-name line-height-13 mb-4">
        {{ product.name }}
      </h1>

      <div class="product-short-descr">
        {{ product.shortDescr }} {{ product.description }}
      </div>
      <div class="cod-int text-caption mb-2">
        COD. {{ product.codInt }}-{{ product.codVar }}
      </div>
      <v-row
        no-gutters
        class="mb-4 mb-sm-0 mb-md-3 mt-3"
        justify="center"
        justify-sm="start"
      >
        <ProductVendor
          v-if="product.vendor && product.vendor.url"
          :vendor="product.vendor"
        />
        <ProductClass
          :pClass="pClass"
          :width="34"
          :height="34"
          v-for="pClass in productClasses"
          :key="pClass.productClassId"
        />
      </v-row>

      <div style="position: relative">
        <div class="product-badge novita" v-if="product.newProduct">
          Novità
        </div>
        <div
          class="product-badge"
          :class="product.productInfos.BADGE"
          v-if="product.productInfos.BADGE"
        >
          {{ product.productInfos.BADGE }}
        </div>
        <ProductPromo
          v-if="product.warehousePromo"
          :warehousePromo="product.warehousePromo"
        />
        <div
          v-if="
            product.warehousePromo &&
              product.warehousePromo.view &&
              product.warehousePromo.view.bubble
          "
          class="promo_bubble"
          v-html="product.warehousePromo.view.bubble"
        ></div>
        <product-gift-certificate
          v-for="userGiftCertificate in product.userGiftCertificates"
          :key="userGiftCertificate.giftCertificate.giftCertificateId"
          :giftCertificate="userGiftCertificate.giftCertificate"
          :containerRef="$refs.productCard"
          @add="giftAdded"
          style="width:fit-content;"
        />
      </div>
      <div class="product-price-row d-flex align-center mt-3 mb-10">
        <ProductPrice :product="product"></ProductPrice>

        <v-btn
          plain
          elevation="0"
          @click.stop.prevent="addToFavorites"
          @mousedown.stop
          aria-label="Aggiungi ad una lista"
        >
          <v-icon color="primary" v-if="highlight">$starFull</v-icon>
          <v-icon v-else color="grey darken-1">$star</v-icon>
          <span>{{ $t("product.addToList") }}</span>
        </v-btn>
        <div v-if="product.leadTime" class="lead-time-container">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                contain
                class="lead-time-img"
                v-bind="attrs"
                v-on="on"
                eager
                src="/img_layout/product/lead-time.png"
                alt="lead time"
                title="lead time"
              >
                <div class="lead-time-overlay">
                  {{
                    $t("product.leadTimeHours", {
                      hours: product.leadTime * 24
                    })
                  }}
                </div></v-img
              >
            </template>
            <span>{{
              $t("product.leadTime", { hours: product.leadTime * 24 })
            }}</span>
          </v-tooltip>
        </div>
      </div>

      <v-row
        v-if="product.available > 0"
        class="product-buy-actions d-flex align-center justify-space-between"
      >
        <v-col cols="6">
          <QtyWrap
            :key="key"
            :selectedOptions="selectedOptions"
            :product="product"
            @minusClicked="minusClicked"
            @plusClicked="plusClicked"
          />
        </v-col>
        <v-col
          cols="6"
          v-if="selectOptionsArray && selectOptionsArray.length > 0"
        >
          <SelectedOptionsMenu
            :product="product"
            :selectOptionsArray="selectOptionsArray"
            @onOptionChanged="onOptionChanged"
            @setSelectOptions="setSelectOptions"
          />
        </v-col>
        <v-col
          :cols="selectOptionsArray && selectOptionsArray.length > 0 ? 12 : 6"
        >
          <v-btn
            large
            block
            class="add-to-cart-btn"
            outlined
            color="primary"
            @click.stop.prevent="
              addToCart(quantity, { infos: { ...selectedOptions } })
            "
            @mousedown.stop
          >
            <v-icon>$cart</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div
        v-else-if="product.available == 0"
        class="product-no-available justify-center text-center text-caption error--text font-weight-bold"
      >
        {{ $t("product.notAvailable") }}
      </div>
    </v-col>
    <v-col cols="12" sm="6" class="py-0 py-sm-3">
      <ProductImages
        class="mt-6 mt-sm-0"
        :images="product.media"
        :name="product.name"
        :key="product.productId"
      />
    </v-col>
  </v-row>
</template>
<style lang="scss">
.product-badge {
  width: 90px !important;
}
.product-detail {
  .select-options-selector {
    height: initial !important;
  }
  .product-name {
    font-size: 2.25rem;
    font-weight: bold;
  }
  .promo-container {
    max-width: 90px;
    .promo-footer {
      align-items: flex-start;
    }
  }
  .product-price {
    min-width: 130px;
  }
  .price {
    margin-bottom: 5px;
  }
  .product-no-available {
    min-height: 58px;
  }
  .product-price-row {
    gap: 20px;
  }
  .product-buy-actions {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 500px;
    }
    .qty-wrap,
    .select-options-selector {
      height: 44px;
    }
    .select-options-selector {
      .v-text-field--outlined.v-input--dense.v-text-field--outlined
        > .v-input__control
        > .v-input__slot,
      .v-text-field--outlined > .v-input__control > .v-input__slot {
        min-height: 44px;
      }
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      position: fixed;
      bottom: 0px;
      left: 0px;
      z-index: 3;
      width: 100%;
      margin: 0px;
      background-color: $white;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    }
  }

  .promo-wrapper {
    //position: initial;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    .certificate-box {
      margin-right: 2px;
      max-width: 900px;
    }
  }

  .user-action-preferences {
    width: 165px;
  }

  .promo-wrapper {
    max-height: 73px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: auto;
    }
  }
  .old-price {
    font-size: 1rem !important;
  }
  .cur-price {
    font-size: 36px;
  }
}
.is-cordova.platform-ios {
  .product-detail {
    .product-buy-actions {
      bottom: calc(
        #{$bottom-navigation-bar-height-ios} + env(safe-area-inset-top) - 2px
      ) !important;
      // bottom: calc(#{$bottom-navigation-bar-height-ios});
    }
  }
}
.is-cordova.keyboard-open {
  .product-detail {
    .product-buy-actions {
      bottom: 0px !important;
      // bottom: calc(#{$bottom-navigation-bar-height-ios});
    }
  }
}
.is-cordova {
  .product-detail {
    .product-buy-actions {
      bottom: calc(
        #{$bottom-navigation-bar-height} + env(safe-area-inset-top) + 20px
      ) !important;
      z-index: 5 !important;
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import ProductPromo from "@/components/product/ProductPromo.vue";
import ProductClass from "@/components/product/ProductClass.vue";
import ProductVendor from "@/components/product/ProductVendor.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import QtyWrap from "./QtyWrap.vue";
import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";
import ProductGiftCertificate from "../gift/ProductGiftCertificate.vue";
import productMixin from "~/mixins/product";
import { mapState, mapGetters } from "vuex";

import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductDetail",
  props: ["selectedImage", "product"],
  components: {
    ProductGiftCertificate,
    ProductPrice,
    ProductPromo,
    ProductClass,
    ProductVendor,
    ProductImages,
    QtyWrap,
    SelectedOptionsMenu
  },
  mixins: [productMixin],
  data() {
    return {
      imageDetailDialog: false,
      selectedOptions: {},
      selectOptionsArray: [],
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      return null;
    }
  },
  methods: {
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    plusClicked() {
      this.plus();
    },
    minusClicked() {
      this.minus();
    }
  },
  mounted() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
  },
  watch: {
    product(val, oldVal) {
      if (val.productId !== oldVal.productId) {
        if (val.selectOptions.length > 0) {
          this.selectOptionsArray = cloneDeep(val.selectOptions);
        }
      }
    }
  }
};
</script>
